<script setup>
import { ref,inject,onMounted,provide  } from 'vue'
import axios from 'axios';
import { useMemberStore } from '../../stores';
const userStore = useMemberStore();
const baseURL = 'https://mini.walkertour.cn';
const rootPath = "/www/wwwroot/miniServer/server/dist";

const billid = inject('billid');
const forbidEdit = inject('forbidEdit');
const size = ref('default')
const labelPosition = ref('right')
const imageUrl = ref('')
const headers = ref({
	'Authorization': `${userStore.profile?.token}`,
})

const sizeForm = inject('orderForm');

onMounted(async()=>{
	if(billid.value!=null&&billid.value!=''){
		const response = await axios.post('/manageDriver/driver/queryInfo', {
		  billid:billid.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			sizeForm.value = response.data.data[0];
			imageUrl.value = sizeForm.value.avatar
		}
	}
})
function onSubmit() {
  console.log('submit!')
}
const handleAvatarSuccess = (
  response,
  uploadFile
) => {
	if(response.status===0){
		imageUrl.value = response.imgUrl.replace(rootPath,baseURL);
		sizeForm.value.logo = response.imgUrl;
	}
}

const beforeAvatarUpload = (rawFile) => {
  if (rawFile.type !== 'image/jpeg') {
    ElMessage.error('Avatar picture must be JPG format!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}
</script>
<template>
	<el-form
	    ref="form"
	    :model="sizeForm"
	    label-width="auto"
	    :label-position="labelPosition"
	    :size="size"
	  >
		<div style="display: flex;">
			<el-form-item label="姓名" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.xm" />
			</el-form-item>
			<el-form-item label="性别" style="width: 300px;">
			  <el-radio-group :disabled="forbidEdit" v-model="sizeForm.sex">
				<el-radio border label="男" value="男"/>
				<el-radio border label="女" value="女"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="手机号" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.mobile" />
			</el-form-item>
			<el-form-item label="紧急手机号" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.em_mobile" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="状态">
			  <el-radio-group :disabled="forbidEdit" v-model="sizeForm.zt">
				<el-radio border label="空闲中" value="空闲中"/>
				<el-radio border label="已安排" value="已安排"/>
				<el-radio border label="暂不可用" value="暂不可用"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="头像">
				<el-upload
			      class="avatar-uploader"
			      action="/manageDriver/driver/uploadFile"
				  :headers="headers"
			      :show-file-list="false"
			      :on-success="handleAvatarSuccess"
			      :before-upload="beforeAvatarUpload"
				  crossorigin="anonymous"
			    >
			      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
			      <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
			    </el-upload>
			</el-form-item>
		</div>
	  </el-form>
</template>

<style scoped>
	.avatar-uploader .avatar {
	  width: 90px;
	  height: 90px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 90px;
	  height: 90px;
	  text-align: center;
	}
</style>