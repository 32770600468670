"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMemberStore = void 0;
var pinia_1 = require("pinia");
var vue_1 = require("vue");
// 定义 Store
exports.useMemberStore = (0, pinia_1.defineStore)('member', function () {
    // 会员信息
    var profile = (0, vue_1.ref)();
    // 保存会员信息，登录时使用
    var setProfile = function (val) {
        profile.value = val;
    };
    // 清理会员信息，退出时使用
    var clearProfile = function () {
        profile.value = undefined;
    };
    // 记得 return
    return {
        profile: profile,
        setProfile: setProfile,
        clearProfile: clearProfile,
    };
}, {
    // 网页端配置
    persist: true,
});
