<template>
  <div class="content">
    <input
      v-if="mapType == 'selectPoint'"
      id="pac-input"
      class="controls"
      type="text"
      :placeholder="L('请输入关键字')"
    />
    <div :style="googleMapStyle" class="googleMap" :id="mapID"></div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader' //引入
export default {
props: {
    //mapType属性值为:
    //selectPoint时是地图选点
    //POLYGON是绘制地图多边形区域
    //地图id
    mapID: {
      type: String,
      default: () => {
        return 'googleMap'
      },
    },
    //谷歌地图类型
    mapType: {
      type: String,
      default: () => {
        return 'default'
      },
    },
    //谷歌地图样式
    googleMapStyle: {
      type: Object,
      default: () => {
        return {
          wdith: '100%',
          height: '500px',
        }
      },
    },
    //谷歌地图配置
    mapOptions: {
      type: Object,
      default: () => {
        return {
          //为了关闭默认控件集,设置地图的disableDefaultUI的属性为true
          disableDefaultUI: false,
          // 启用缩放和平移
          gestureHandling: 'greedy',
          panControl: true,
          zoomControl: true,
          scaleControl: true,
          //关闭街景
          streetViewControl: false,
        }
      },
    },
    //谷歌地图中心点
    googleMapCenter: {
      type: Object,
      default: () => {
        return {
          lat: 33.33036636210688,
          lng: 126.59677112102509,
        }
      },
    },
    //谷歌地图缩放级别
    zoom: {
      type: Number,
      default() {
        return 11
      },
    },
    //谷歌地图图形path
    mapPath: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
    data() {
      return {
        //地图选点回显值
        longlat: this.googleMapCenter.lng + ',' + this.googleMapCenter.lat,
        //标记点
        marker: [],
        //图形实例
        graphicalExample: null,
        //图形路径经纬度
        graphicalPath: [],
        apiKey: 'AIzaSyBY0zE4q-hsPgb8qQbiJ4VKFt3h376g4sU'
      }
    },
    created() {
        this.$nextTick(() => {
          const loader = new Loader({
			  apiKey: this.apiKey, //之前的key
			  version: 'weekly', //版本
			  libraries: ['places', 'drawing'], //插件库places为基础库 drawing为绘制工具库
			  region: 'Canada',
			  language: 'en',
		})
        const mapOptions = {
          center: { lat: this.googleMapCenter.lat * 1, lng: this.googleMapCenter.lng * 1 }, //中心点
          zoom: this.zoom, //缩放级别
          ...this.mapOptions, //其他配置
        }
        loader
          .load()
          .then((google) => {
            const map = new google.maps.Map(document.getElementById(this.mapID), mapOptions)
            this.googleMap = map
            this.googleApi = google
          })
          .catch((e) => {
            // do something
            console.log(e)
          })
      })
    }
}
</script>

<style>
</style>