<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #prepend>
			    <el-select v-model="type" placeholder="条件类型" style="width: 115px">
			      <el-option label="姓名" value="1" />
			      <el-option label="手机号" value="2" />
			      <el-option label="状态" value="3" />
			    </el-select>
			  </template>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="xm" label="姓名" width="130" />
	    <el-table-column fixed prop="mobile" label="手机号" width="200" />
	    <el-table-column prop="em_mobile" label="紧急电话" width="200" />
	    <el-table-column prop="sex" label="性别" width="200" />
	    <el-table-column prop="zt" label="状态" width="200" />
	    <el-table-column fixed="right" label="操作" width="*">
	      <template #default="scope">
	        <el-button link type="primary" size="small" @click="handleDtClick(scope.row)">详情</el-button>
	        <el-button link type="primary" size="small" @click="addBz(scope.row)">备注</el-button>
			<el-button link type="primary" size="small" @click="deleteData(scope.row)">删除</el-button>
			<el-button link type="primary" size="small" @click="linkMiniUser(scope.row)">关联微信用户</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
	<el-dialog
		@close="closeDtDialog"
	    v-model="jsjDialogVisible"
	    title="司机信息"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<DataForm />
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="closeDtDialog">关闭</el-button>
			<el-button v-if="forbidEdit" type="primary" @click="editForm">编辑</el-button>
		    <el-button v-if="!forbidEdit" type="primary" @click="saveForm">保存</el-button>
		  </span>
		</template>
	</el-dialog>
	<el-dialog
	    v-model="bzDialogVisible"
	    title="添加备注"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<el-input class="w-50 m-2" v-model="newBz" />
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="bzDialogVisible = false">关闭</el-button>
		    <el-button type="primary" @click="saveBz">保存</el-button>
		  </span>
		</template>
	</el-dialog>
	<el-dialog
	    v-model="linkUserDialogVisible"
	    title="关联微信用户"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<el-select
			v-model="miniUser"
			filterable
			placeholder="选择用户"
			style="width: 600px"
		>
			<el-option
			  v-for="item in miniUserDatas"
			  :key="item.value"
			  :label="item.label"
			  :value="item.value"
			/>
		</el-select>
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="linkUserDialogVisible = false">关闭</el-button>
		    <el-button type="primary" @click="savelinkAge">保存</el-button>
		  </span>
		</template>
	</el-dialog>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import DataForm from './sjInfo.vue';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	
	const linkUserDialogVisible = ref(false)
	const miniUserDatas = ref([])
	const miniUser = ref('')
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const searchType = ref(0);
	const key = ref('');
	const type = ref('');
	const tableData = ref([]);
	const jsjDialogVisible = ref(false);//详情
	const bzDialogVisible = ref(false);//备注
	const newBz = ref('');
	const billid = ref('');
	const forbidEdit = ref(true);
	provide('forbidEdit', forbidEdit);
	provide('billid', billid);
	const orderForm = ref({
		xm:'',
		mobile:'',
		em_mobile:'',
		sex:'',
		zt:'',
		avatar:'',
		bz:'',
	})
	provide('orderForm', orderForm);
	onMounted(async()=>{
		const response = await axios.post('/manageDriver/driver/queryList', {
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	})
	const linkMiniUser=async(e)=>{
		billid.value = e.id;
		const response = await axios.post('/api/linkage/queryUsers', {
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			const datas = response.data.datas;
			const sjOptions = [];
			datas.map(item=>{
				sjOptions.push({
					value:item.id,
					label:item.id+"/"+item.name+"/"+item.mobile,
					mobile:item.mobile
				})
			})
			miniUserDatas.value = sjOptions;
		}
		linkUserDialogVisible.value = true;
	}
	const savelinkAge=async()=>{
		const response = await axios.post('/manageDriver/driver/saveLinkage', {
			userID:miniUser.value,
			driverID:billid.value
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '关联成功!',
				type: 'success',
			})
			linkUserDialogVisible.value = false
		}else{
			ElMessage.error('哦,关联失败!')
		}
	}
	const saveBz = async() =>{
		const response = await axios.post('/manageDriver/driver/updateSingle', {
		  billid:billid.value,
		  key:'bz',
		  value:newBz.value
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '信息保存成功!',
				type: 'success',
			})
		}else{
			ElMessage.error('哦,信息保存失败!')
		}
		tableData.value.map(item=>{
			if(item.id===billid.value){
				item.bz = newBz.value;
			}
		})
		bzDialogVisible.value = false;
	}
	const fetchOrders = async () => {
		searchType.value = 0;
		const response = await axios.post('/manageDriver/driver/queryList', {
		  type: type.value,
		  key:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	};
	const handleDtClick = (e)=>{
		billid.value = e.id;
		jsjDialogVisible.value = true;
	}
	const deleteData=async(e)=>{
		const response = await axios.post('/manageDriver/driver/delete', {
		  billid:e.id,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '删除成功!',
				type: 'success',
			})
			fetchOrders()
		}
	}
	const addBz = async (e) =>{
		billid.value = e.id;
		const response = await axios.post('/manageDriver/driver/queryInfo', {
		  billid:billid.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			newBz.value = response.data.data[0].bz;
		}
		bzDialogVisible.value = true;
	}
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
	const closeDtDialog=()=>{
		forbidEdit.value = true;
		jsjDialogVisible.value = false;
	}
	const editForm = ()=>{
		forbidEdit.value = false;
	}
	const saveForm = async () =>{
		const response = await axios.post('/manageDriver/driver/update', {
		  billid:billid.value,
		  ...orderForm.value
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '信息保存成功!',
				type: 'success',
			})
			tableData.value.map(item=>{
				if(item.id===billid.value){
					item.xm = orderForm.value.xm
					item.mobile = orderForm.value.mobile
					item.em_mobile = orderForm.value.em_mobile
					item.sex = orderForm.value.sex
					item.zt = orderForm.value.zt
					item.avatar = orderForm.value.avatar
					item.bz = orderForm.value.bz
				}
			})
		}else{
			ElMessage.error('哦,信息保存失败!')
		}
		jsjDialogVisible.value = false;
	}
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>