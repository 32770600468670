<script setup>
import { ref,inject,onMounted,provide  } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios';
import { useMemberStore } from '../../stores';
const userStore = useMemberStore();
const baseURL = 'https://mini.walkertour.cn';
const rootPath = "/www/wwwroot/miniServer/server/dist";

const billid = ref(1);//inject('billid');
const forbidEdit = ref(false);
const size = ref('default')
const labelPosition = ref('right')
const imageUrl = ref('')
const headers = ref({
	'Authorization': `${userStore.profile?.token}`,
})

const sizeForm = ref({
	mc:'',
	cllx:'',
	logo:'',
	fwts:'',
	disprice:'',
	minprice:'',
	pp:'',
	color:'',
	cph:'',
	zt:'',
	ykj:'',
	ckrs:''
});
onMounted(async()=>{
	// if(billid.value!=null&&billid.value!=''){
	// 	const response = await axios.post('', {
	// 	  billid:billid.value,
	// 	}, {
	// 	  headers: {
	// 	    'Content-Type': 'application/json',
	// 		Authorization: `${userStore.profile?.token}`,
	// 	  }
	// 	});
	// 	if(response.data.status===0){
	// 		sizeForm.value = response.data.datas[0];
	// 	}
	// }
})
const onSubmit=async()=> {
	let isNull = false;
	Object.keys(sizeForm.value).forEach(key=>{
		if(sizeForm.value[key]===''){
			isNull = true;
		}
	})
	if(!isNull){
		const response = await axios.post('/manageCar/car/add', {
		  ...sizeForm.value
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage.success('添加成功!')
		}
	}else{
		ElMessage.error('所有信息不可为空!')
	}
}
const handleAvatarSuccess= (
  response,
  uploadFile
) => {
  if(response.status===0){
  	imageUrl.value = response.imgUrl.replace(rootPath,baseURL);
  	sizeForm.value.logo = response.imgUrl;
  }
}

const beforeAvatarUpload = (rawFile) => {
  if (rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
    ElMessage.error('Avatar picture must be JPG/PNG format!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}
</script>
<template>
	<el-form
	    ref="form"
	    :model="sizeForm"
	    label-width="auto"
	    :label-position="labelPosition"
	    :size="size"
	>
		<div style="display: flex;">
			<el-form-item label="公司名称" style="width: 48%;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.mc" />
			</el-form-item>
			<el-form-item label="车辆类型" style="width: 48%;">
			  <el-radio-group :disabled="forbidEdit" v-model="sizeForm.cllx">
				<el-radio border label="经济" value="经济"/>
				<el-radio border label="舒适" value="舒适"/>
				<el-radio border label="商务" value="商务"/>
				<el-radio border label="VIP" value="VIP"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="品牌" style="width: 48%;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.pp" />
			</el-form-item>
			<el-form-item label="颜色" style="width: 48%;">
			  <el-radio-group :disabled="forbidEdit" v-model="sizeForm.color">
				<el-radio border label="白色" value="白色"/>
				<el-radio border label="灰色" value="灰色"/>
				<el-radio border label="银色" value="银色"/>
				<el-radio border label="黑色" value="黑色"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="车牌号" style="width: 48%;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.cph" />
			</el-form-item>
			<el-form-item label="服务特色" style="width: 48%;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.fwts" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="距离单价" style="width: 48%;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.disprice" />
			</el-form-item>
			<el-form-item label="时间单价" style="width: 48%;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.minprice" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="车辆实图" style="width: 48%;">
			  <el-upload
			    class="avatar-uploader"
			    action="/manageDriver/driver/uploadFile"
			    :headers="headers"
			    :show-file-list="false"
			    :on-success="handleAvatarSuccess"
			    :before-upload="beforeAvatarUpload"
				crossorigin="anonymous"
			  >
			    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
			    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
			  </el-upload>
			</el-form-item>
			<el-form-item label="一口价" style="width: 48%;align-items: center;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.ykj" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="车辆状态" style="width: 48%;">
			  <el-radio-group :disabled="forbidEdit" v-model="sizeForm.zt">
				<el-radio border label="空闲中" value="空闲中"/>
				<el-radio border label="使用中" value="使用中"/>
				<el-radio border label="维护中" value="维护中"/>
			  </el-radio-group>
			</el-form-item>
			<el-form-item label="最大乘客人数" style="width: 48%;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.ckrs" />
			</el-form-item>
		</div>
		<div style="display: flex;justify-content: center;">
			<el-form-item>
		      <el-button type="primary" @click="onSubmit">保存</el-button>
		      <el-button>重置</el-button>
		    </el-form-item>
		</div>
	</el-form>
</template>

<style scoped>
	.avatar-uploader .avatar {
	  width: 90px;
	  height: 90px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 90px;
	  height: 90px;
	  text-align: center;
	}
</style>