<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
		<el-table-column fixed prop="billid" label="数据ID" width="80" />
		<el-table-column fixed prop="mobile" label="用户手机号" width="130" />
	    <el-table-column fixed prop="mc" label="优惠名称" width="130" />
	    <el-table-column fixed prop="lx" label="优惠类型" width="120" />
	    <el-table-column prop="syfwshow" label="使用范围" width="120" />
	    <el-table-column prop="zk" label="优惠金额/折扣" width="150" />
	    <el-table-column prop="kssj" label="有效期开始" width="200" />
		<el-table-column prop="jssj" label="有效期结束" width="200" />
		<el-table-column prop="ztshow" label="状态" width="80" />
	    <el-table-column fixed="right" label="操作" width="*">
	      <template #default="scope">
			<el-button link type="primary" size="small" @click="deleteData(scope.row)">删除</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import DataForm from './yhqInfo.vue';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const key = ref('');
	const tableData = ref([]);
	onMounted(async()=>{
		const response = await axios.post('/coupon/yhq/queryGrantList', {
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	})
	const fetchOrders = async () => {
		const response = await axios.post('/coupon/yhq/queryGrantList', {
		  key:'b.mc',
		  value:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	};
	const handleDtClick = (e)=>{
		billid.value = e.id;
		jsjDialogVisible.value = true;
	}
	const deleteData=async(e)=>{
		const response = await axios.post('/coupon/yhq/delGrantYhq', {
		  billid:e.billid,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '删除成功!',
				type: 'success',
			})
			fetchOrders()
		}
		
	}
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>