<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #prepend>
			    <el-select v-model="type" placeholder="条件类型" style="width: 115px">
			      <el-option label="品牌" value="1" />
			      <el-option label="颜色" value="2" />
			      <el-option label="状态" value="3" />
			    </el-select>
			  </template>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="name" label="名称" width="120" />
	    <el-table-column fixed prop="zxjg" label="咨询机构" width="80" />
	    <el-table-column prop="lxlx" label="联系方式" width="120" />
		<el-table-column prop="lxhm" label="联系号码" width="120" />
		<el-table-column prop="birthdate" label="出生日期" width="420" />
		<el-table-column prop="createdate" label="登记时间" width="420" />
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const searchType = ref(0);
	const key = ref('');
	const type = ref('');
	const tableData = ref([]);
	const billid = ref('');
	onMounted(async()=>{
		const response = await axios.post('/consult/getConsultList', {
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	})
	const fetchOrders = async () => {
		searchType.value = 0;
		const response = await axios.post('/consult/getConsultList', {
		  type: type.value,
		  key:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	};
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>