<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-button style="margin: 0 10px;" type="primary" plain icon="Search" @click="centerDialogVisible = true">高级搜索</el-button>
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #prepend>
			    <el-select v-model="type" placeholder="条件类型" style="width: 115px">
			      <el-option label="订单号" value="1" />
			      <el-option label="机场" value="2" />
			      <el-option label="车型" value="3" />
			    </el-select>
			  </template>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
		
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="atddh" label="敖途订单号" width="130" />
	    <el-table-column fixed prop="kkddh" label="kk订单号" width="120" />
	    <el-table-column prop="xdsj" label="下单时间" width="200" />
	    <el-table-column prop="sysj" label="使用时间" width="200" />
	    <el-table-column prop="jc" label="机场" width="60" />
	    <el-table-column prop="jslx" label="接送" width="60" />
		<el-table-column prop="krscx" label="人数/车型" width="120" />
		<el-table-column prop="ddzt" label="状态" width="80" />
		<el-table-column prop="fjfw" label="附加服务" width="200" />
		<el-table-column prop="bz" label="备注" width="120" />
	    <el-table-column fixed="right" label="操作" width="100">
	      <template #default="scope">
	        <el-button link type="primary" size="small" @click="handleDtClick(scope.row)">详情</el-button>
	        <el-button link type="primary" size="small" @click="addBz(scope.row)">备注</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" @prev-click="prePage" @next-click="nextPage" background layout="prev, pager, next" :total="total" />
	</div>
	<el-dialog
	    v-model="centerDialogVisible"
	    title="高级搜索"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<div>
			<h4>
				按下单时间
			</h4>
			<div class="dialog-cond-dt">
				<label class="dialog-cond-label">开始时间:</label>
				<el-date-picker
					v-model="xdkssj"
					type="datetime"
					placeholder="选择开始日期和时间"
					:shortcuts="shortcuts"
				/>
				<label class="dialog-cond-label">结束时间:</label>
				<el-date-picker
					v-model="xdjssj"
					type="datetime"
					placeholder="选择结束日期和时间"
					:shortcuts="shortcuts"
				/>
			</div>
		</div>
	    <div>
	    	<h4>
	    		按使用时间
	    	</h4>
	    	<div class="dialog-cond-dt">
	    		<label class="dialog-cond-label">开始时间:</label>
	    		<el-date-picker
	    			v-model="sykssj"
	    			type="datetime"
	    			placeholder="选择开始日期和时间"
	    			:shortcuts="shortcuts"
	    		/>
	    		<label class="dialog-cond-label">结束时间:</label>
	    		<el-date-picker
	    			v-model="syjssj"
	    			type="datetime"
	    			placeholder="选择结束日期和时间"
	    			:shortcuts="shortcuts"
	    		/>
	    	</div>
	    </div>
		<div>
			<h4>
				按订单状态
			</h4>
			<div class="dialog-cond-dt">
				<label class="dialog-cond-label">选择状态:</label>
				<el-select
				  v-model="ddzt"
				  multiple
				  collapse-tags
				  placeholder="订单状态选择"
				  style="width: 240px"
				>
				  <el-option
					v-for="item in ddztItems"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				  />
				</el-select>
			</div>
		</div>
		<!-- <div>
			<h4>
				按城市
			</h4>
			<div class="dialog-cond-dt">
				<label class="dialog-cond-label">选择城市:</label>
				<el-select
				  v-model="citySelect"
				  multiple
				  collapse-tags
				  placeholder="选择城市"
				  style="width: 240px"
				>
				  <el-option
					v-for="item in cityItems"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				  />
				</el-select>
			</div>
		</div> -->
		<div>
			<h4>
				按订单号
			</h4>
			<div class="dialog-cond-dt" style="width: 320px;display: flex;">
				<label class="dialog-cond-label">敖途/KK订单编号:</label>
				<el-input v-model="ddbh" :clearable="true" class="w-50 m-2" placeholder="请输入完整的订单编号" />
			</div>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="centerDialogVisible = false">取消</el-button>
			<el-button @click="resetParams">重置</el-button>
	        <el-button type="primary" @click="highSearchOrders"
	          >搜索</el-button
	        >
	      </span>
	    </template>
	</el-dialog>
	<el-dialog
	    @close="closeDtDialog"
	    v-model="jsjDialogVisible"
	    title="接送机服务"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<DataForm />
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="closeDtDialog">关闭</el-button>
			<el-button v-if="forbidEdit" type="primary" @click="editForm">编辑</el-button>
		    <el-button v-if="!forbidEdit" type="primary" @click="saveForm">保存</el-button>
		  </span>
		</template>
	</el-dialog>
	<el-dialog
	    v-model="bzDialogVisible"
	    title="添加备注"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<el-input class="w-50 m-2" v-model="newBz" />
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="bzDialogVisible = false">关闭</el-button>
		    <el-button type="primary" @click="saveBz">保存</el-button>
		  </span>
		</template>
	</el-dialog>
</template>

<script setup>
	import { ref,provide } from 'vue';
	import axios from 'axios';
	import DataForm from './JsjDataForm.vue';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	
	const billid = ref('');
	const forbidEdit = ref(true);
	provide('forbidEdit', forbidEdit);
	provide('billid', billid);
	const orderForm = ref({
	  atddh: '',//敖途订单号
	  dpsj: '',//订票时间
	  sysj: '',//使用时间
	  kkddh: '',//klook订单编号
	  yyrxm: '',//预订人姓名
	  jslx: '',//接送类型
	  jc: '',//机场
	  cx: '',//车型
	  from:'',//出发地
	  to:'',//目的地
	  hbh:'',//航班号
	  sjh:'',//手机号
	  email:'',//邮箱地址
	  krs:0,//客人数
	  fjfw:'',//附加服务
	  tsxq:'',//特殊需求
	  qtlxfs:'',//其他联系方式
	  djkrw:0,//单价
	  sl:0,//数量
	  zjkrw:0,//总价
	  ydts:0,//预定天数
	  ddzt:'',//订单状态
	  bz:'',//备注
	  qxsj:'',//取消时间
	  atfsyjsj:'',//敖途发送邮件时间
	})
	provide('orderForm', orderForm);
	const key = ref('');
	const type = ref('');
	const ddzt = ref([]);
    const citySelect = ref([]);
	const ddbh = ref('');
	const syjssj = ref('');
	const sykssj = ref('');
	const xdkssj = ref('');
	const xdjssj = ref('');
	
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	
	
	const searchType = ref(0)
	
	const tableData = ref([]);
	const centerDialogVisible = ref(false);//高级搜索
	const jsjDialogVisible = ref(false);//详情
	const bzDialogVisible = ref(false);//备注
	const newBz = ref('')
	const shortcuts = [
	  {
	    text: '一周前',
	    value: () => {
	      const date = new Date()
	      date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
	      return date
	    },
	  },{
	    text: '昨　天',
	    value: () => {
	      const date = new Date()
	      date.setTime(date.getTime() - 3600 * 1000 * 24)
	      return date
	    },
	  },{
	    text: '今　天',
	    value: new Date(),
	  },{
	    text: '明　天',
	    value: () => {
	      const date = new Date()
	      date.setTime(date.getTime() + 3600 * 1000 * 24)
	      return date
	    },
	  },{
	    text: '一周后',
	    value: () => {
	      const date = new Date()
	      date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
	      return date
	    },
	  },
	  
	]
	const ddztItems = [
	  {
	    value: '已确认',
	    label: '已确认',
	  },
	  {
	    value: '已取消',
	    label: '已取消',
	  }
	]
	const cityItems = [
	  {
	    value: '首尔',
	    label: '首尔',
	  },
	  {
	    value: '济州',
	    label: '济州',
	  },
	  {
	    value: '釜山',
	    label: '釜山',
	  }
	]
	const saveBz = async() =>{
		const response = await axios.post('/my/jj-updateOrderInfo', {
		  billid:billid.value,
		  "`备注`":"'" + newBz.value + "'"
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '信息保存成功!',
				type: 'success',
			})
			tableData.value.map(item=>{
				if(item.atddh===billid.value){
					item.bz = newBz.value;
				}
			})
		}else{
			ElMessage.error('哦,信息保存失败!')
		}
		bzDialogVisible.value = false;
	}
	const addBz = async (e) =>{
		billid.value = e.atddh;
		const response = await axios.post('/my/jj-queryOrderInfo', {
		  billid:billid.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			newBz.value = response.data.datas[0].bz;
		}
		bzDialogVisible.value = true;
	}
	const closeDtDialog=()=>{
		forbidEdit.value = true;
		jsjDialogVisible.value = false;
	}
	const editForm = ()=>{
		forbidEdit.value = false;
	}
	const saveForm = async()=>{
		const response = await axios.post('/my/jj-updateOrderInfo', {
		  billid:billid.value,
		  "`敖途订单编号`":"'" + orderForm.value.atddh + "'",//
		  "`订票时间`":"'" + orderForm.value.dpsj + "'",//
		  "`使用时间`":"'" + orderForm.value.sysj + "'",//
		  "`klook订单编号`":"'" + orderForm.value.kkddh + "'",//
		  "`预订人姓名`":"'" + orderForm.value.yyrxm + "'",//
		  "`接送类型`":"'" + orderForm.value.jslx + "'",//
		  // "`机场`":"'" + orderForm.value.jc + "'",
		  "`车型`":"'" + orderForm.value.cx + "'",//
		  "`航班号`":"'" + orderForm.value.hbh + "'",//
		  "`手机号`":"'" + orderForm.value.sjh + "'",//
		  "email":"'" + orderForm.value.email + "'",//
		  // "`客人数`":"'" + orderForm.value.krs + "'",
		  "`附加服务`":"'" + orderForm.value.fjfw + "'",//
		  "`特殊需求`":"'" + orderForm.value.tsxq + "'",//
		  // "`其他联系方式`":"'" + orderForm.value.qtlxfs + "'",
		  // "`单价 (KRW)`":"'" + orderForm.value.djkrw + "'",
		  // "`数量`":"'" + orderForm.value.sl + "'",
		  "`总价 (KRW)`":"'" + orderForm.value.zjkrw + "'",//
		  "`预订天数`":"'" + orderForm.value.ydts + "'",//
		  "`订单状态`":"'" + orderForm.value.ddzt + "'",//
		  // "`备注`":"'" + orderForm.value.bz + "'",
		  // "`取消时间`":"'" + orderForm.value.qxsj + "'",
		  // "`敖途发送邮件时间`":"'" + orderForm.value.atfsyjsj + "'",
		  "`from`":"'" + orderForm.value.from + "'",//
		  "`to`":"'" + orderForm.value.to + "'"//
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '信息保存成功!',
				type: 'success',
			})
			tableData.value.map(item=>{
				if(item.atddh===billid.value){
					item.atddh = orderForm.value.atddh;
					item.kkddh = orderForm.value.kkddh;
					item.xdsj = orderForm.value.dpsj;
					item.sysj = orderForm.value.sysj;
					item.jc = orderForm.value.jc;
					item.jslx = orderForm.value.jslx;
					item.krscx = orderForm.value.krs + "/" + orderForm.value.cx;
					item.bz = orderForm.value.bz;
					item.fjfw = orderForm.value.fjfw;
					item.ddzt = orderForm.value.ddzt;
				}
			})
		}else{
			ElMessage.error('哦,信息保存失败!')
		}
		jsjDialogVisible.value = false;
	}
	const handleDtClick = (e)=>{
		billid.value = e.atddh;
		jsjDialogVisible.value = true;
	}
	const changePageNo = (val) => {
	  pageNo.value = val;
	  if(searchType.value===1){
		highSearchOrders();  
	  }else if(searchType.value===0){
		  fetchOrders();
	  }
	}
	const prePage =(val)=>{
		pageNo.value = val;
		if(searchType.value===1){
			highSearchOrders();  
		}else if(searchType.value===0){
			fetchOrders();
		}
	}
	const nextPage =(val)=>{
       pageNo.value = val;
	   if(searchType.value===1){
	   		highSearchOrders();  
	   }else if(searchType.value===0){
	   		fetchOrders();
	   }
	}
	const resetParams = ()=>{
        ddzt.value = [];
        citySelect.value = [];
        ddbh.value = '';
        syjssj.value = '';
        sykssj.value = '';
        xdkssj.value = '';
        xdjssj.value = '';
	}
	const highSearchOrders = async()=>{
		searchType.value = 1;
		type.value = '';
		key.value = '';
		centerDialogVisible.value = false
		const response = await axios.post('/my/jj-queryHighsearch', {
		  syjssj:syjssj.value,
		  sykssj:sykssj.value,
		  xdkssj:xdkssj.value,
		  xdjssj:xdjssj.value,
		  ddzt:ddzt.value.join(','),
		  citySelect:citySelect.value.join(','),
		  ddbh:ddbh.value,
		  jsjType:'送',
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.datas;
			total.value = response.data.total;
		}
	}
	const fetchOrders = async () => {
		searchType.value = 0;
		const response = await axios.post('/my/jj-quicksearch', {
		  type: type.value,
		  key:key.value,
		  jsjType:'送',
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.datas;
			total.value = response.data.total;
		}
	};
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>