<template>
	<div class="common-layout left-right-content">
	    <el-container>
	      <el-header class="main-header-tool" style="padding: 0 20px 0 0;">
			  <div style="margin-right: auto;">
				  <img style="width: 200px; height: 120px" src="../static/walker.jpg" fit="fill" ></img>
			  </div>
			  <div class="toolbar">
				<el-icon :size="20" style="margin-right: 15px" @click="setMiniItem"><setting color="#fff"/></el-icon>
			    <el-dropdown>
			      <el-icon :size="20" style="margin-right: 15px;"><Avatar color="#fff"/></el-icon>
			      <template #dropdown>
			        <el-dropdown-menu>
			          <el-dropdown-item>我的人事</el-dropdown-item>
			          <el-dropdown-item>修改密码</el-dropdown-item>
			          <el-dropdown-item @click="logout">退出</el-dropdown-item>
			        </el-dropdown-menu>
			      </template>
			    </el-dropdown>
			    <span> {{ userStore.profile?.name }} </span>
			  </div>
		  </el-header>
	      <el-container>
	        <el-aside class="left-content">
				<el-scrollbar height="560px">
					<el-menu
						active-text-color="#ffd04b"
						default-active="1"
						class="el-menu-vertical-demo"
						:router="true"
						@open="handleOpen"
						@close="handleClose"
					  >
						<el-sub-menu index="1">
						  <template #title>
							<el-icon><Menu /></el-icon>
							<span>订单管理</span>
						  </template>
						  <el-menu-item class="sub-menu-fontsize" index="/index/jsj-jj-order">接/送机订单</el-menu-item>
						</el-sub-menu>
						<el-sub-menu index="2">
						  <template #title>
							<el-icon><Menu /></el-icon>
							<span>车辆管理</span>
						  </template>
						  <el-menu-item class="sub-menu-fontsize" index="/car/car-add">新增车辆</el-menu-item>
						  <el-menu-item class="sub-menu-fontsize" index="/car/car-manage">监控车辆</el-menu-item>
						</el-sub-menu>
						<el-sub-menu index="3">
						  <template #title>
							<el-icon><Menu /></el-icon>
							<span>司机管理</span>
						  </template>
						  <el-menu-item class="sub-menu-fontsize" index="/driver/driver-add">新增司机</el-menu-item>
						  <el-menu-item class="sub-menu-fontsize" index="/driver/driver-manage">查询司机</el-menu-item>
						</el-sub-menu>
						<el-sub-menu index="4">
						  <template #title>
							<el-icon><Menu /></el-icon>
							<span>客服管理</span>
						  </template>
						  <el-menu-item class="sub-menu-fontsize" index="/kf/kf-add">新增客服</el-menu-item>
						  <el-menu-item class="sub-menu-fontsize" index="/kf/kf-manage">查询客服</el-menu-item>
						</el-sub-menu>
						<el-sub-menu index="5">
						  <template #title>
							<el-icon><Menu /></el-icon>
							<span>优惠券管理</span>
						  </template>
						  <el-menu-item class="sub-menu-fontsize" index="/coupon/coupon-add">新增优惠券</el-menu-item>
						  <el-menu-item class="sub-menu-fontsize" index="/coupon/coupon-manage">查询优惠券</el-menu-item>
						  <el-menu-item class="sub-menu-fontsize" index="/coupon/coupon-granted">已发放优惠券</el-menu-item>
						</el-sub-menu>
						<el-sub-menu index="6">
						  <template #title>
							<el-icon><Menu /></el-icon>
							<span>司机注册审核</span>
						  </template>
						  <el-menu-item class="sub-menu-fontsize" index="/dregister/register-todo?type=todo">待审核</el-menu-item>
						  <el-menu-item class="sub-menu-fontsize" index="/dregister/register-pass?type=passed">审核通过</el-menu-item>
						  <el-menu-item class="sub-menu-fontsize" index="/dregister/register-nopass?type=nopass">审核退回</el-menu-item>
						</el-sub-menu>
						<el-sub-menu index="7">
						  <template #title>
							<el-icon><Menu /></el-icon>
							<span>酒店预定管理</span>
						  </template>
						  <el-menu-item class="sub-menu-fontsize" index="/mall/good-add">房间添加</el-menu-item>
						  <el-menu-item class="sub-menu-fontsize" index="/mall/good-list">房间查询</el-menu-item>
						</el-sub-menu>
						<el-sub-menu index="8">
						  <template #title>
							<el-icon><Menu /></el-icon>
							<span>咨询管理</span>
						  </template>
						  <el-menu-item class="sub-menu-fontsize" index="/consult/consult-list">咨询机构</el-menu-item>
						  <el-menu-item class="sub-menu-fontsize" index="/consult/consult-info-list">咨询人员</el-menu-item>
						</el-sub-menu>
					  </el-menu>
				</el-scrollbar>
			</el-aside>
	        <el-container>
	          <el-main class="right-content">
				  <RouterView />
			  </el-main>
	        </el-container>
	      </el-container>
	    </el-container>
	  </div>
	  <el-dialog
	      v-model="setDialogVisible"
	      title="小程序显示模块设置"
	      width="50%"
	      destroy-on-close
	      center
	  	:modal="false"
	  >
		<div style="display: flex;justify-content: center;align-items: center;margin-bottom: 12px;" v-for="(item,index) in settings" :key='index'>
			<div style="width:70% ;">
				<el-input v-model="item.mkmc" style="width: 360px" placeholder="请输入模块名称" />
			</div>
			<div style="width:30% ;">
				<el-radio-group v-model="item.sfkq">
					<el-radio border label="启用" value="0"/>
					<el-radio border label="禁用" value="1"/>
				</el-radio-group>
			</div>
		</div>
	  	<template #footer>
	  	  <span class="dialog-footer">
	  	    <el-button @click="cancelSettings">取消</el-button>
			<el-button type="primary" @click="saveSettings">保存</el-button>
			<el-button type="primary" @click="addSettings">新增</el-button>
	  	  </span>
	  	</template>
	  </el-dialog>
</template>
<script setup>
	import { useMemberStore } from '../stores';
	import { computed,ref } from 'vue'
	import axios from 'axios';
	import { useRoute, useRouter } from 'vue-router'
	import { ElMessage } from 'element-plus'
	 
	const setDialogVisible = ref(false)
	const settings = ref([])
	
	const router = useRouter();
	const route = useRoute();

	const userStore = useMemberStore();
	const logout = ()=>{
		userStore.clearProfile();
		router.push('/login'); // 退出后跳转到登录页面
	}
	const handleOpen = (key, keyPath) => {
	  console.log(key, keyPath)
	}
	const handleClose = (key, keyPath) => {
	  console.log(key, keyPath)
	}
	const setMiniItem=async()=>{
		setDialogVisible.value = true;
		const res = await axios.post('/api/mini/settings', {
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(res.data.status===0){
			settings.value = res.data.data;
		}
	}
	const cancelSettings=()=>{
		setDialogVisible.value = false;
	}
	const saveSettings=async()=>{
		const res = await axios.post('/api/mini/saveSettings', {
			settings: JSON.stringify(settings.value) 
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		ElMessage.success('保存成功!');
		setDialogVisible.value = false;
	}
	const addSettings=async()=>{
		const res = await axios.post('/api/mini/addSettings', {
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(res.data.status===0){
			settings.value.push({
				id: res.data.data.insertId,
				mkmc: '新增模块',
				sfkq: '0'
			})
		}
	}
</script>

<style scoped>
	.main-header-tool{
		display: flex; /* 设置为flex布局 */
		justify-content: flex-end;
		align-items: center;
		background-color: #545c64;
		color: #fff;
		font-size: 18px;
	}
	.left-right-content{
		display: flex;
		height: 675px;
	}
	.left-content{
		width: 200px;
		height: 100%;
	}
	.right-content{
		width: calc(100%-200px);
		height: 100%;
	}
	.sub-menu-fontsize{
		font-size: 14px; /* 设置子菜单的字体大小 */
	}
</style>