<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="mc" label="优惠名称" width="130" />
	    <el-table-column fixed prop="lx" label="优惠类型" width="120" />
	    <el-table-column prop="syfwshow" label="使用范围" width="120" />
	    <el-table-column prop="zk" label="优惠金额/折扣" width="150" />
	    <el-table-column prop="kssj" label="有效期开始" width="200" />
		<el-table-column prop="jssj" label="有效期结束" width="200" />
		<el-table-column prop="ztshow" label="状态" width="120" />
	    <el-table-column fixed="right" label="操作" width="*">
	      <template #default="scope">
	        <el-button link type="primary" size="small" @click="handleDtClick(scope.row)">详情</el-button>
			<el-button link type="primary" size="small" @click="grantCoupon(scope.row)">发放优惠券</el-button>
			<el-button link type="primary" size="small" @click="deleteData(scope.row)">删除</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
	<el-dialog
		@close="closeDtDialog"
	    v-model="jsjDialogVisible"
	    title="优惠券"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<DataForm />
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="closeDtDialog">关闭</el-button>
			<el-button v-if="forbidEdit" type="primary" @click="editForm">编辑</el-button>
		    <el-button v-if="!forbidEdit" type="primary" @click="saveForm">保存</el-button>
		  </span>
		</template>
	</el-dialog>
	<el-dialog
	    v-model="ffyhqDialogVisible"
	    title="发放优惠券"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<el-form
		    ref="formffyhq"
		    :model="yhqForm"
		    label-width="auto"
		    label-position="right"
		    size="default"
		  >
			<div style="display: flex;">
				<el-form-item label="发放人员" style="width: 500px;" prop="ffry" :rules="[{ required: true, message: 'accepts-info is required' }]">
					<el-select
						v-model="yhqForm.ffry"
						filterable
						placeholder="选择人员"
						style="width: 440px"
						:multiple="true"
						:clearable="true"
						collapse-tags
					>
						<el-option
						  v-for="item in miniUserDatas"
						  :key="item.value"
						  :label="item.label"
						  :value="item.value"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="全选" style="width: 200px;" prop="sfqx">
				  <el-checkbox v-model="yhqForm.sfqx" label="全选" size="large" border />
				</el-form-item>
			</div>
		</el-form>
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="quitFf">取消</el-button>
		    <el-button type="primary" @click="comfirmFf">确认</el-button>
		  </span>
		</template>
	</el-dialog>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import DataForm from './yhqInfo.vue';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const key = ref('');
	const tableData = ref([]);
	const jsjDialogVisible = ref(false);//详情
	const ffyhqDialogVisible = ref(false)//发放优惠券
	const yhqForm = ref({
		couponid:-1,
		ffry:[],
		sfqx:false
	})
	const miniUserDatas = ref([])
	const formffyhq = ref(null)
	const newBz = ref('');
	const billid = ref('');
	const forbidEdit = ref(true);
	provide('forbidEdit', forbidEdit);
	provide('billid', billid);
	const orderForm = ref({
		mc:'',//优惠券名称
		lx:'',//类型  折扣或者满减
		syfw:'',//使用范围 接送机
		syfwshow:'',
		zk:'',//折扣或者满减值
		kssj:'',//开始时间
		jssj:'',//结束时间
		zdje:'',//最低金额 金额满多少可以用
		zt:'',//状态启用 禁用
		ztshow:''
	})
	provide('orderForm', orderForm);
	onMounted(async()=>{
		const response = await axios.post('/coupon/yhq/queryList', {
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	})
	const fetchOrders = async () => {
		const response = await axios.post('/coupon/yhq/queryList', {
		  key:'mc',
		  value:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	};
	const quitFf=()=>{
		ffyhqDialogVisible.value = false
		yhqForm.value = {
			couponid:-1,
			ffry:[],
			sfqx:false
		}
	}
	const grantCoupon=async(e)=>{
		yhqForm.value.couponid = e.id;
		ffyhqDialogVisible.value = true;
		const res = await axios.post('/coupon/yhq/queryMiniUserList', {
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(res.data.status===0){
			miniUserDatas.value = res.data.data.map(item=>{
				return {
					label:item.id+"/"+item.name+"/"+item.mobile,
					value:item.id,
				}
			})
		}
	}
	const comfirmFf=()=>{
		if (!formffyhq.value) return
		formffyhq.value.validate(async(valid)=>{
			if(valid){
				const response = await axios.post('/coupon/yhq/grantCoupon', {
				  ...yhqForm.value
				}, {
				  headers: {
					'Content-Type': 'application/json',
					Authorization: `${userStore.profile?.token}`,
				  }
				});
				if(response.data.status===0){
					ElMessage({
						message: '发放成功!',
						type: 'success',
					})
					ffyhqDialogVisible.value = false
					yhqForm.value = {
						couponid:-1,
						ffry:[],
						sfqx:false
					}
				}else{
					ElMessage.error('哦,发放失败!')
				}
			}else{
				ElMessage.error('请填写完整信息')
			}
		})
	}
	const handleDtClick = (e)=>{
		billid.value = e.id;
		jsjDialogVisible.value = true;
	}
	const deleteData=async(e)=>{
		const response = await axios.post('/coupon/yhq/delData', {
		  billid:e.id,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '删除成功!',
				type: 'success',
			})
			fetchOrders()
		}
		
	}
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
	const closeDtDialog=()=>{
		forbidEdit.value = true;
		jsjDialogVisible.value = false;
	}
	const editForm = ()=>{
		forbidEdit.value = false;
	}
	const saveForm = async () =>{
		const response = await axios.post('/coupon/yhq/saveOrUpdate', {
		  billid:billid.value,
		  ...orderForm.value
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '信息保存成功!',
				type: 'success',
			})
			tableData.value.map(item=>{
				if(item.id===billid.value){
					item.mc = orderForm.value.mc;
					item.lx = orderForm.value.lx;
					item.syfwshow = orderForm.value.syfwshow;
					item.zk = orderForm.value.zk;
					item.kssj = orderForm.value.kssj;
					item.jssj = orderForm.value.jssj;
					item.zdje = orderForm.value.zdje;
					item.ztshow = orderForm.value.ztshow;
				}
			})
		}else{
			ElMessage.error('哦,信息保存失败!')
		}
		jsjDialogVisible.value = false;
	}
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>