<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="xm" label="姓名" width="130" />
	    <el-table-column fixed prop="kflj" label="客服链接" width="500" />
	    <el-table-column prop="zt" label="状态" width="100" />
	    <el-table-column fixed="right" label="操作" width="*">
	      <template #default="scope">
	        <el-button link type="primary" size="small" @click="online(scope.row)">上线</el-button>
	        <el-button link type="primary" size="small" @click="offline(scope.row)">下线</el-button>
			<el-button link type="primary" size="small" @click="deleteData(scope.row)">删除</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="pageSize" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const key = ref('');
	const tableData = ref([]);
	onMounted(async() => {
		const response = await axios.post('/customerService/kf/queryList', {
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	});
	const fetchOrders = async () => {
		const response = await axios.post('/customerService/kf/queryList', {
		  key:'xm',
		  value:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	};
	const online=async(e)=>{
		const response = await axios.post('/customerService/kf/updateZt', {
		  billid:e.id,
		  zt:'在线'
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			fetchOrders();
		}
	}
	const offline=async(e)=>{
		const response = await axios.post('/customerService/kf/updateZt', {
		  billid:e.id,
		  zt:'下线'
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			fetchOrders();
		}
	}
	const deleteData=async(e)=>{
		const response = await axios.post('/customerService/kf/delData', {
		  billid:e.id,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '删除成功!',
				type: 'success',
			})
			fetchOrders()
		}
	}
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>
