import { createRouter, createWebHashHistory } from 'vue-router';
import { useMemberStore } from '../stores';
import axios from 'axios';
import MainVue from '../views/Main.vue';
import LoginVue from '../views/Login.vue';
import JsjOrderJjVue from '../views/jsj/JsjOrderJj.vue';
import JsjOrderSjVue from '../views/jsj/JsjOrderSj.vue';
import SjInfoListVue from '../views/sj/sjInfoList.vue';
import SjInfoAddVue from '../views/sj/sjInfoAdd.vue';
import ClInfoListVue from '../views/cl/clInfoList.vue';
import ClInfoAddVue from '../views/cl/clInfoAdd.vue';
import KfInfoAddVue from '../views/kf/kfInfoAdd.vue';
import KfInfoListVue from '../views/kf/kfInfoList.vue';
import GmapVue from '../views/gmap.vue';
import YhqInfoAddVue from '../views/yhq/yhqInfoAdd.vue';
import YhqInfoListVue from '../views/yhq/yhqInfoList.vue';
import YhqGrantListVue from '../views/yhq/yhqGrantList.vue';
import RegisterSjListVue from '../views/sj/registerSjList.vue';
import RegisterSjPassListVue from '../views/sj/registerSjPassList.vue';
import RegisterSjNoPassListVue from '../views/sj/registerSjNoPassList.vue';
import GoodInfoAddVue from '../views/mall/goodInfoAdd.vue';
import GoodInfoListVue from '../views/mall/goodInfoList.vue';
import ConsultListVue from '../views/consult/consultList.vue';
import ConsultInfoListVue from '../views/consult/consultInfoList.vue';

const routes = [
	{
		path: '/index', 
        component: MainVue,
        meta: { 
			requiresAuth: true,
			title:'首页'
		},
		children: [
		    {
		        path: 'jsj-jj-order',
		        component: JsjOrderJjVue,
				meta: { 
					requiresAuth: true ,
					title:'接机订单'
				},
		    },
		    {
		        path: 'jsj-sj-order',
		        component: JsjOrderSjVue,
				meta: { 
					requiresAuth: true,
					title:'送机订单' ,
				},
		    },
		],
	},
    { 
		path: '/', 
		redirect: '/index',
		meta: { 
			requiresAuth: true ,
			title:'首页' ,
		},
	},
	{ 
		path: '/login', 
		component: LoginVue,
		meta: { 
			requiresAuth: false,
			title:'登录' ,
		},
	},
	{
		path: '/driver', 
		component: MainVue,
		meta: { 
			requiresAuth: true,
			title:'司机管理'
		},
		children: [
		    {
		        path: 'driver-add',
		        component: SjInfoAddVue,
				meta: { 
					requiresAuth: true ,
					title:'司机添加'
				},
		    },
		    {
		        path: 'driver-manage',
		        component: SjInfoListVue,
				meta: { 
					requiresAuth: true,
					title:'司机管理' ,
				},
		    },
		]
	},
	{
		path: '/car', 
		component: MainVue,
		meta: { 
			requiresAuth: true,
			title:'车辆管理'
		},
		children: [
		    {
		        path: 'car-add',
		        component: ClInfoAddVue,
				meta: { 
					requiresAuth: true ,
					title:'车辆新增'
				},
		    },
		    {
		        path: 'car-manage',
		        component: ClInfoListVue,
				meta: { 
					requiresAuth: true,
					title:'车辆管理' ,
				},
		    },
		]
	},
	{
		path: '/kf', 
		component: MainVue,
		meta: { 
			requiresAuth: true,
			title:'客服管理'
		},
		children: [
		    {
		        path: 'kf-add',
		        component: KfInfoAddVue,
				meta: { 
					requiresAuth: true ,
					title:'车辆客服'
				},
		    },
		    {
		        path: 'kf-manage',
		        component: KfInfoListVue,
				meta: { 
					requiresAuth: true,
					title:'查询客服' ,
				},
		    },
			{
			    path: 'gmap',
			    component: GmapVue,
				meta: { 
					requiresAuth: true,
					title:'地图' ,
				},
			},
		]
	},{
		path: '/coupon', 
		component: MainVue,
		meta: { 
			requiresAuth: true,
			title:'优惠券管理'
		},
		children: [
		    {
		        path: 'coupon-add',
		        component: YhqInfoAddVue,
				meta: { 
					requiresAuth: true ,
					title:'添加优惠券'
				},
		    },
		    {
		        path: 'coupon-manage',
		        component: YhqInfoListVue,
				meta: { 
					requiresAuth: true,
					title:'查看优惠券' ,
				},
		    },
		    {
		        path: 'coupon-granted',
		        component: YhqGrantListVue,
				meta: { 
					requiresAuth: true,
					title:'已发放优惠券' ,
				},
		    }
		]
	},
	{
		path: '/dregister', 
		component: MainVue,
		meta: { 
			requiresAuth: true,
			title:'司机注册审核'
		},
		children: [
		    {
		        path: 'register-todo',
		        component: RegisterSjListVue,
				meta: { 
					requiresAuth: true ,
					title:'待审核'
				},
		    },
			{
			    path: 'register-pass',
			    component: RegisterSjPassListVue,
				meta: { 
					requiresAuth: true ,
					title:'审核通过'
				},
			},
			{
			    path: 'register-nopass',
			    component: RegisterSjNoPassListVue,
				meta: { 
					requiresAuth: true ,
					title:'审核退回'
				},
			}
		]
	},
	{
		path: '/mall', 
		component: MainVue,
		meta: { 
			requiresAuth: true,
			title:'酒店管理'
		},
		children: [
		    {
		        path: 'good-add',
		        component: GoodInfoAddVue,
				meta: { 
					requiresAuth: true ,
					title:'房间添加'
				},
		    },
			{
			    path: 'good-list',
			    component: GoodInfoListVue,
				meta: { 
					requiresAuth: true ,
					title:'房间查询'
				},
			}
		]
	},
	{
		path: '/consult', 
		component: MainVue,
		meta: { 
			requiresAuth: true,
			title:'咨询管理'
		},
		children: [
		    {
		        path: 'consult-list',
		        component: ConsultListVue,
				meta: { 
					requiresAuth: true ,
					title:'咨询机构'
				},
		    },
			{
			    path: 'consult-info-list',
			    component: ConsultInfoListVue,
				meta: { 
					requiresAuth: true ,
					title:'咨询人员'
				},
			}
		]
	}
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach(async(to, from, next) => {
  const userStore = useMemberStore();
  document.title = to.meta.title || '敖途旅行';
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 判断用户是否已登录
    if (userStore.profile?.token) {
		if(!userStore.profile?.token){
			next("/login");
		}else{
			const res = await axios.post('/api/check', {
			  userid: userStore.profile.userid,
			  token: userStore.profile.token,
			}, {
			  headers: {
			    'Content-Type': 'application/json'
			  }
			});
			if(res.data.status===0){
				if(res.token!==undefined){
					userStore.setProfile({
						mobile: userStore.profile.mobile,
						token: res.data.token,
						userid: userStore.profile.userid,
						name: userStore.profile.name,
					});
				}
				// 已登录，则跳转到目标路由（需要用户登录的页面）
				next();
			}else{
				//登录过期
				next("/login");
			}
		}
    } else {
		// 未登录，跳转到登录页
		next("/login");
    }
  } else {
		// 公共页面，直接跳转到目标路由
		next();
  }
});
export default router;
