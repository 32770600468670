<script setup>
import { ref,inject,onMounted,provide  } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios';
import { useMemberStore } from '../../stores';
const userStore = useMemberStore();
const baseURL = 'https://mini.walkertour.cn';
const rootPath = "/www/wwwroot/miniServer/server/dist";

const billid = inject('billid');
const forbidEdit = inject('forbidEdit');
const size = ref('default')
const labelPosition = ref('right')
const imageUrl = ref('')
const headers = ref({
	'Authorization': `${userStore.profile?.token}`,
})
const dataTree = ref([
    { value: "0", text: "经济" },
    { value: "1", text: "舒适" },
    { value: "2", text: "商务" },
    { value: "3", text: "豪华" }
  ])
const colors = ref([{
    text: '白色',
    value: "0"
  }, {
    text: '黑色',
    value: "1"
  }, {
    text: '灰色',
    value: "2"
  }, {
    text: '银色',
    value: "3"
  }, {
    text: '红色',
    value: "4"
  }, {
    text: '其他',
    value: "5"
  }])
const setDatas = ref([{
    text: '男',
    value: "0"
  }, {
    text: '女',
    value: "1"
  }])
const sizeForm = inject('orderForm');

onMounted(async()=>{
	if(billid.value!=null&&billid.value!=''){
		const response = await axios.post('/registerDriver/regDriver/queryInfo', {
		  id:billid.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			sizeForm.value = response.data.data;
		}
	}
})
function onSubmit() {
  console.log('submit!')
}
</script>
<template>
	<el-form
	    ref="form"
	    :model="sizeForm"
	    label-width="auto"
	    :label-position="labelPosition"
	    :size="size"
	  >
		<div>
			<el-form-item>
				<div  style="display: flex;width: 100%;justify-content: center;">
					<img :src="sizeForm.avatar" style="width: 100px;height: 100px;border-radius: 50%;" alt="头像" />
				</div>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="姓名:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{sizeForm.name}}</el-text>
			</el-form-item>
			<el-form-item label="性别:" style="width: 48%;">
				<el-text class="mx-1" type="primary">{{setDatas[sizeForm.sex].text}}</el-text>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="手机号:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{sizeForm.mobile}}</el-text>
			</el-form-item>
			<el-form-item label="紧急联系手机号:" style="width: 48%;">
				<el-text class="mx-1" type="primary">{{sizeForm.em_mobile}}</el-text>
			</el-form-item>
		</div>
		<div style="display: flex;">
		    <el-form-item label="中国银行卡号:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{sizeForm.zhBankCardNo}}</el-text>
		    </el-form-item>
		    <el-form-item label="中国银行卡开户行:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{sizeForm.zhBankCardIssue}}</el-text>
		    </el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="韩国银行卡号:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{sizeForm.keBankCardNo}}</el-text>
			</el-form-item>
			<el-form-item label="韩国银行开户行:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{sizeForm.keBankCardIssue}}</el-text>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="车辆注册地:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{sizeForm.regAdd}}</el-text>
			</el-form-item>
			<el-form-item label="车型:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{dataTree[sizeForm.type]?.text}}</el-text>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="车牌号码:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{sizeForm.carNo}}</el-text>
			</el-form-item>
			<el-form-item label="车辆颜色:" style="width: 48%;">
			  <el-text class="mx-1" type="primary">{{colors[sizeForm.color]?.text}}</el-text>
			</el-form-item>
		</div>
		<div>
			<el-form-item label="身份证:" >
				<div  style="display: inline-block;width: 100%;">
				  <div v-for="(item,index) in sizeForm.idCard">
					<img :src="item" style="width:80%;" alt="身份证" />
				  </div>
				</div>
			</el-form-item>
		</div>
		<div>
			<el-form-item label="驾驶证:" >
				<div  style="display: inline-block;width: 100%;">
				  <div v-for="(item,index) in sizeForm.license">
					<img :src="item" style="width:80%;" alt="驾驶证" />
				  </div>
				</div>
			</el-form-item>
		</div>
		<div>
			<el-form-item label="车辆登录证:" >
				<div  style="display: inline-block;width: 100%;">
				  <div v-for="(item,index) in sizeForm.cldlz">
					<img :src="item" style="width:80%;" alt="车辆登录证" />
				  </div>
				</div>
			</el-form-item>
		</div>
		<div>
			<el-form-item label="保险:" >
				<div  style="display: inline-block;width: 100%;">
				  <div v-for="(item,index) in sizeForm.bx">
					<img :src="item" style="width:80%;" alt="保险" />
				  </div>
				</div>
			</el-form-item>
		</div>
		<div>
			<el-form-item label="车辆实况:">
			  <div  style="display: inline-block;width: 100%;">
				  <div v-for="(item,index) in sizeForm.imgs">
				    <img :src="item" style="width:80%;" alt="车辆实况" />
				  </div>
			  </div>
			</el-form-item>
		</div>
	  </el-form>
</template>

<style scoped>
	.avatar-uploader .avatar {
	  width: 90px;
	  height: 90px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 90px;
	  height: 90px;
	  text-align: center;
	}
</style>