<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-upload
			  class="avatar-uploader"
			  action="https://server.walkertour.cn/consult/uploadFile"
			  :headers="headers"
			  :data="{type:uploadType}"
			  :show-file-list="false"
			  :on-success="handleAvatarSuccess"
			  :before-upload="beforeAvatarUpload"
			  rossorigin="anonymous"
			>
			  <el-select v-model="uploadType" placeholder="类型" style="width: 115px">
			    <el-option label="原辰" value="原辰" />
			    <el-option label="知友" value="知友" />
			    <el-option label="hera" value="hera" />
				<el-option label="mm" value="mm" />
			  </el-select>
			  <el-icon class="avatar-uploader-icon"><Plus /></el-icon>
			</el-upload>
			&nbsp;&nbsp;
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #prepend>
			    <el-select v-model="type" placeholder="条件类型" style="width: 115px">
			      <el-option label="品牌" value="1" />
			      <el-option label="颜色" value="2" />
			      <el-option label="状态" value="3" />
			    </el-select>
			  </template>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="mc" label="名称" width="320" />
	    <el-table-column fixed prop="lx" label="类型" width="120" />
	    <el-table-column prop="url" label="访问路径" width="520" />
	    <el-table-column fixed="right" label="操作" width="*">
	      <template #default="scope">
			<el-button link type="primary" size="small" @click="deleteData(scope.row)">删除</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	const headers = ref({
		'Authorization': `${userStore.profile?.token}`,
	})
	const uploadType = ref('原辰')
	const handleAvatarSuccess= (
	  response,
	  uploadFile
	) => {
	  if(response.status===0){
		  ElMessage.success('上传成功');
		  fetchOrders()
	  }
	}
	const beforeAvatarUpload = (rawFile) => {
	  if (rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
	    ElMessage.error('Avatar picture must be JPG/PNG format!')
	    return false
	  } else if (rawFile.size / 1024 / 1024 > 4) {
	    ElMessage.error('Avatar picture size can not exceed 4MB!')
	    return false
	  }
	  return true
	}
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const searchType = ref(0);
	const key = ref('');
	const type = ref('');
	const tableData = ref([]);
	const billid = ref('');
	onMounted(async()=>{
		const response = await axios.post('/consult/getFileList', {
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	})
	const fetchOrders = async () => {
		searchType.value = 0;
		const response = await axios.post('/consult/getFileList', {
		  type: type.value,
		  key:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	};
	const deleteData=async(e)=>{
		const response = await axios.post('/consult/deleteFile', {
		  billid:e.id,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '删除成功!',
				type: 'success',
			})
			fetchOrders()
		}
		
	}
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>