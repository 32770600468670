<template>
  <div class="loginVideo">
    <video
      src="../static/vido.mp4"
      playsInline
      autoPlay
      muted
      :loop="true"
      :controls="false"
      preload="auto"
    />
    <div class="loginBox">
      <div class="container">
        <div class="drop">
          <div class="content">
            <h2 :style="{ color: '#3399ff' }">敖途旅行(深圳)</h2>
            <form>
              <div class="inputBox">
                <input type="text" v-model="username" placeholder="用户名" @keyup.enter="handleLogin"/>
              </div>
              <div class="inputBox">
                <input type="password" v-model="password" placeholder="密码" @keyup.enter="handleLogin" />
              </div>
              <div class="inputBox" :style="{ margin: '0 auto' }">
                <input
                  :style="{ color: 'white' }"
                  type="button"
                  value="登录"
                  @click="handleLogin"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script setup>
import { ref } from "vue";
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useMemberStore } from '../stores'
import { ElMessage } from 'element-plus'

const router = useRouter();
const username = ref('');
const password = ref('');
const userStore = useMemberStore();
const handleLogin = async () => {
	if(username.value===''){
		ElMessage({
			message: '请输入用户名',
			type: 'warning',
		})
		return;
	}
	if(password.value===''){
		ElMessage({
			message: '请输入密码',
			type: 'warning',
		})
		return;
	}
  try {
    const response = await axios.post('/api/login', {
      username: username.value,
      password: password.value,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
	
    if (response.data.status === 0) {
		userStore.setProfile({
			mobile: response.data.mobile,
        	token: response.data.token,
        	userid: response.data.userid,
        	name: response.data.name,
		});
		router.push({ path: '/index' });
    }else{
		ElMessage.error('账号密码错误,请重新输入!')
	}
  } catch (error) {
    ElMessage.error(error)
  }
};
</script>
 
<style scoped>
.loginVideo {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
 
video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
 
.loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
 
/* 登录框 */
.container .drop {
  position: relative;
  width: 350px;
  height: 350px;
  box-shadow: inset 20px 20px 20px rgba(0, 0, 0, 0.05),
    25px 35px 20px rgba(0, 0, 0, 0.05), 25px 30px 30px rgba(0, 0, 0, 0.05),
    inset -20px -20px 25px rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
 
.container .drop:hover {
  border-radius: 10%;
}
 
.container .drop::before {
  content: "";
  position: absolute;
  top: 50px;
  left: 85px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.9;
}
 
.container .drop::after {
  content: "";
  position: absolute;
  top: 90px;
  left: 110px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.9;
}
 
.container .drop .content {
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  text-align: center;
  padding: 40px;
  gap: 15px;
}
 
.container .drop .content h2 {
  position: relative;
  color: #333;
  font-size: 1.5em;
}
 
.container .drop .content form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  /* align-items: center; */
}
 
.container .drop .content form .inputBox {
  position: relative;
  width: 225px;
  box-shadow: inset 2px 5px 10px rgba(0, 0, 0, 0.1),
    inset -2px -5px 10px rgba(255, 255, 255, 1),
    15px 15px 10px rgba(0, 0, 0, 0.05), 15px 10px 15px rgba(0, 0, 0, 0.025);
  border-radius: 25px;
}
 
/* .container .drop .content form .inputBox::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  width: 65%;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
} */
 
.container .drop .content form .inputBox input {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  font-size: 1em;
  padding: 10px 15px;
}
 
.container .drop .content form .inputBox input[type="submit"] {
  color: #fff;
  text-transform: uppercase;
  font-size: 1em;
  cursor: pointer;
  letter-spacing: 0.1em;
  font-weight: 500;
}
 
.container .drop .content form .inputBox:last-child {
  width: 120px;
  background: #3399ff;
  box-shadow: inset 2px 5px 10px rgba(0, 0, 0, 0.1),
    15px 15px 10px rgba(0, 0, 0, 0.05), 15px 10px 15px rgba(0, 0, 0, 0.025);
  transition: 0.5s;
}
 
.container .drop .content form .inputBox:last-child:hover {
  width: 150px;
}
 
.btns {
  position: absolute;
  right: -120px;
  bottom: 0;
  width: 120px;
  height: 120px;
  background: #00a6bc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  line-height: 1.2em;
  letter-spacing: 0.1em;
  font-size: 0.8em;
  transition: 0.25s;
  text-align: center;
  box-shadow: inset 10px 10px 10px rgba(0, 166, 188, 0.05),
    15px 25px 10px rgba(0, 166, 188, 0.1), 15px 20px 20px rgba(0, 166, 188, 0.1),
    inset -10px -10px 15px rgba(0, 166, 188, 0.5);
  border-radius: 50%;
}
 
/* .btns::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 30px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.45;
} */
 
.btns.signup {
  bottom: 150px;
  right: -120px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #00a6bc;
  box-shadow: inset 10px 10px 10px rgba(0, 166, 188, 0.05),
    15px 25px 10px rgba(0, 166, 188, 0.1), 15px 20px 20px rgba(0, 166, 188, 0.1),
    inset -10px -10px 15px rgba(0, 166, 188, 0.5);
}
 
.btns.signup::before {
  left: 20px;
  width: 15px;
  height: 15px;
}
 
.btns:hover {
  border-radius: 10%;
}
</style>