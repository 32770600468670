<script setup>
import { ref,inject,onMounted,provide  } from 'vue'
import axios from 'axios';
import { useMemberStore } from '../../stores';
const userStore = useMemberStore();

const billid = inject('billid');
const forbidEdit = inject('forbidEdit');
const size = ref('default')
const labelPosition = ref('right')

const sizeForm = inject('orderForm');

onMounted(async()=>{
	if(billid.value!=null&&billid.value!=''){
		const response = await axios.post('/my/jj-queryOrderInfo', {
		  billid:billid.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			sizeForm.value = response.data.datas[0];
		}
	}
})
function onSubmit() {
  console.log('submit!')
}
</script>
<template>
	<el-form
	    ref="form"
	    :model="sizeForm"
	    label-width="auto"
	    :label-position="labelPosition"
	    :size="size"
	  >
		<div style="display: flex;">
			<el-form-item label="敖途订单号" style="width: 300px;">
			  <el-input :disabled="true" class="w-50 m-2" v-model="sizeForm.atddh" />
			</el-form-item>
			<el-form-item label="klook订单号" style="width: 300px;">
			  <el-input :disabled="true" class="w-50 m-2" v-model="sizeForm.kkddh" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="预订人" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.yyrxm" />
			</el-form-item>
			<el-form-item label="服务类型" style="width: 300px;">
			  <el-radio-group :disabled="forbidEdit" v-model="sizeForm.jslx">
			    <el-radio border label="接机" value="接"/>
			    <el-radio border label="送机" value="送"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="使用时间" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.sysj" />
			</el-form-item>
			<el-form-item label="使用天数" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.ydts" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="手机号" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.sjh" />
			</el-form-item>
			<el-form-item label="邮箱" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.email" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="航班号" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.hbh" />
			</el-form-item>
			<el-form-item label="车型" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.cx" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="附加服务" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.fjfw" />
			</el-form-item>
			<el-form-item label="特殊需求" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.tsxq" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="出发地" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.from" />
			</el-form-item>
			<el-form-item label="目的地" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.to" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="下单时间" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.dpsj" />
			</el-form-item>
			<el-form-item label="下单总价" style="width: 300px;">
			  <el-input :disabled="forbidEdit" class="w-50 m-2" v-model="sizeForm.zjkrw" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="订单状态">
			  <el-radio-group :disabled="forbidEdit" v-model="sizeForm.ddzt">
			    <el-radio border label="已确认" value="已确认"/>
			    <el-radio border label="已取消" value="已取消"/>
			  </el-radio-group>
			</el-form-item>
		</div>
	</el-form>
</template>

<style>
</style>