<script setup>
import { ref,inject,onMounted,provide  } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios';
import { useMemberStore } from '../../stores';
const userStore = useMemberStore();
const baseURL = 'https://mini.walkertour.cn';
const rootPath = "/www/wwwroot/miniServer/server/dist";

const size = ref('default')
const labelPosition = ref('right')
const imageUrl = ref('')
const shortcuts0 = [
  {
	text: '今　天',
	value: ()=>{
		return formatDateTime(new Date());
	}
  },{
	text: '明　天',
	value: () => {
	  const date = new Date()
	  date.setTime(date.getTime() + 3600 * 1000 * 24)
	  return formatDateTime(date);
	},
  },{
	text: '一周后',
	value: () => {
	  const date = new Date()
	  date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
	  return formatDateTime(date);
	},
  },{
	text: '一月后',
	value: () => {
	  const date = new Date()
	  date.setTime(date.getTime() + 3600 * 1000 * 24 * 31)
	  return formatDateTime(date);
	},
  },
]
const shortcuts1 = [
  {
	text: '今　天',
	value: ()=>{
		return formatDateTime1(new Date());
	}
  },{
	text: '明　天',
	value: () => {
	  const date = new Date()
	  date.setTime(date.getTime() + 3600 * 1000 * 24)
	  return formatDateTime1(date);
	},
  },{
	text: '一周后',
	value: () => {
	  const date = new Date()
	  date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
	  return formatDateTime1(date);
	},
  },{
	text: '一月后',
	value: () => {
	  const date = new Date()
	  date.setTime(date.getTime() + 3600 * 1000 * 24 * 31)
	  return formatDateTime1(date);
	},
  },
]
const defaultTime0 = (a) => {
	var now = new Date(); // 获取当前日期和时间
	now.setHours(a); // 设置小时为8（24小时制）
	now.setMinutes(0);
	now.setSeconds(0);
	return now
}
const defaultTime1 = (a) => {
	var now = new Date(); // 获取当前日期和时间
	now.setHours(a); // 设置小时为8（24小时制）
	now.setMinutes(59);
	now.setSeconds(59);
	return now
}
const formatDateTime = (date)=>{
	function padZero(num) {
		return num < 10 ? '0' + num : num;
	}
	const year = date.getFullYear();
	const month = padZero(date.getMonth() + 1);
	const day = padZero(date.getDate());
	return `${year}-${month}-${day} 00:00:00`;
}
const formatDateTime1 = (date)=>{
	function padZero(num) {
		return num < 10 ? '0' + num : num;
	}
	const year = date.getFullYear();
	const month = padZero(date.getMonth() + 1);
	const day = padZero(date.getDate());
	return `${year}-${month}-${day} 23:59:59`;
}
const sizeForm = ref({
	mc:'',//优惠券名称 
	lx:'',//类型  折扣或者满减
	syfw:'',//使用范围 接送机
	zk:'',//折扣或者满减值
	kssj:'',//开始时间
	jssj:'',//结束时间
	zdje:'',//最低金额 金额满多少可以用
	zt:''//状态启用 禁用
});
onMounted(async()=>{
	
})
const onSubmit=async()=> {
	let isNull = false;
	Object.keys(sizeForm.value).forEach(key=>{
		if(sizeForm.value[key]===''){
			isNull = true;
		}
	})
	if(!isNull){
		const response = await axios.post('/coupon/yhq/saveOrUpdate', {
		  ...sizeForm.value
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage.success('添加成功!');
			sizeForm.value = {
				mc:'',//优惠券名称 
				lx:'',//类型  折扣或者满减
				syfw:'',//使用范围 接送机
				zk:'',//折扣或者满减值
				kssj:'',//开始时间
				jssj:'',//结束时间
				zdje:'',//最低金额 金额满多少可以用
				zt:''//状态启用 禁用
			}
		}
	}else{
		ElMessage.error('所有信息不可为空!')
	}
}
const handleAvatarSuccess= (
  response,
  uploadFile
) => {
  if(response.status===0){
  	imageUrl.value = response.imgUrl.replace(rootPath,baseURL);
  	sizeForm.value.logo = response.imgUrl;
  }
}

const beforeAvatarUpload = (rawFile) => {
  if (rawFile.type !== 'image/jpeg') {
    ElMessage.error('Avatar picture must be JPG format!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}
</script>
<template>
	<el-form
	    ref="form"
	    :model="sizeForm"
	    label-width="auto"
	    :label-position="labelPosition"
	    :size="size"
	>
		<div style="display: flex;">
			<el-form-item label="优惠名称" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.mc" />
			</el-form-item>
			<el-form-item label="优惠类型" style="width: 48%;">
			  <el-radio-group v-model="sizeForm.lx">
				<el-radio border label="折扣" value="折扣"/>
				<el-radio border label="满减" value="满减"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="最低金额" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.zdje" placeholder="满足最低金额才可使用" />
			</el-form-item>
			<el-form-item label="使用范围" style="width: 48%;">
			  <el-radio-group v-model="sizeForm.syfw">
				<el-radio border label="接送机服务" value="0"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="优惠金额/折扣" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.zk" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="有效期开始" style="width: 48%;">
			  <el-date-picker
			  	v-model="sizeForm.kssj"
			  	type="datetime"
			  	placeholder="选择开始日期和时间"
			  	:shortcuts="shortcuts0"
			  	format="YYYY-MM-DD HH:mm:ss"
			  	:default-time="defaultTime0(0)"
			  	value-format="YYYY-MM-DD HH:mm:ss"
			  />
			</el-form-item>
			<el-form-item label="有效期结束" style="width: 48%;">
			  <el-date-picker
			  	v-model="sizeForm.jssj"
			  	type="datetime"
			  	placeholder="选择结束日期和时间"
			  	:shortcuts="shortcuts1"
			  	format="YYYY-MM-DD HH:mm:ss"
			  	:default-time="defaultTime1(23)"
			  	value-format="YYYY-MM-DD HH:mm:ss"
			  />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="状态">
			  <el-radio-group v-model="sizeForm.zt">
				<el-radio border label="启用" value="0"/>
				<el-radio border label="禁用" value="1"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;justify-content: center;">
			<el-form-item>
		      <el-button type="primary" @click="onSubmit">保存</el-button>
		      <el-button>重置</el-button>
		    </el-form-item>
		</div>
	</el-form>
</template>

<style scoped>
	.avatar-uploader .avatar {
	  width: 90px;
	  height: 90px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 90px;
	  height: 90px;
	  text-align: center;
	}
</style>