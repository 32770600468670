<script setup>
import { ref,inject,onMounted,provide  } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios';
import { useMemberStore } from '../../stores';
const userStore = useMemberStore();

const billid = ref(1);//inject('billid');
const size = ref('default')
const labelPosition = ref('right')
const sizeForm = ref({
	xm:'',
	kflj:'',
	zt:'',
});
onMounted(async()=>{

})
const onSubmit=async()=> {
	let isNull = false;
	Object.keys(sizeForm.value).forEach(key=>{
		if(sizeForm.value[key]===''){
			isNull = true;
		}
	})
  if(!isNull){
  	const response = await axios.post('/customerService/kf/saveOrUpdate', {
  	  ...sizeForm.value
  	}, {
  	  headers: {
  	    'Content-Type': 'application/json',
  		Authorization: `${userStore.profile?.token}`,
  	  }
  	});
  	if(response.data.status===0){
  		ElMessage.success('添加成功!')
  	}
  }else{
	ElMessage.error('信息不可为空!')
  }
}
</script>
<template>
	<el-form
	    ref="form"
	    :model="sizeForm"
	    label-width="auto"
	    :label-position="labelPosition"
	    :size="size"
	>
		<div style="display: flex;">
			<el-form-item label="姓名" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.xm" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="客服链接" style="width: 96%;">
			  <el-input  class="w-50 m-2" v-model="sizeForm.kflj" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="状态" style="width: 96%;">
			  <el-radio-group  v-model="sizeForm.zt">
				<el-radio border label="在线" value="在线"/>
				<el-radio border label="下线" value="下线"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;justify-content: center;">
			<el-form-item>
		      <el-button type="primary" @click="onSubmit">保存</el-button>
		    </el-form-item>
		</div>
	</el-form>
</template>

<style scoped>
	.avatar-uploader .avatar {
	  width: 90px;
	  height: 90px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 90px;
	  height: 90px;
	  text-align: center;
	}
</style>