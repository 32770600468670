<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="name" label="姓名" width="200" />
	    <el-table-column fixed prop="mobile" label="手机号" width="150" />
	    <el-table-column prop="regAdd" label="注册地" width="200" />
	    <el-table-column prop="carNo" label="车牌号" width="150" />
	    <el-table-column prop="rqsj1" label="申请时间" width="200" />
		<el-table-column prop="zt" label="状态" width="120" />
	    <el-table-column fixed="right" label="操作" width="*">
	      <template #default="scope">
	        <el-button link type="primary" size="small" @click="handleDtClick(scope.row)">详情</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
	<el-dialog
		@close="closeDtDialog"
	    v-model="jsjDialogVisible"
	    title="司机注册信息"
	    width="70%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<div class="dialog-custom">
			<DataForm />
		</div>
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="closeDtDialog">关闭</el-button>
		  </span>
		</template>
	</el-dialog>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import DataForm from './registerSjInfo.vue';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const key = ref('');
	const tableData = ref([]);
	const jsjDialogVisible = ref(false);//详情
	const yhqForm = ref({
		couponid:-1,
		ffry:[],
		sfqx:false
	})
	const listType = ref('待审核')
	const miniUserDatas = ref([])
	const formffyhq = ref(null)
	const newBz = ref('');
	const billid = ref('');
	const shyj = ref('')
	provide('billid', billid);
	const orderForm = ref({
	})
	provide('orderForm', orderForm);
	const getQueryVariable = (variable) => {
	  var hash = window.location.hash;
	  if (hash) {
	    var query = hash.substring(1);
	    if (query.split("?")) {
	      query = query.split("?")[1];
	    }
	    var vars = query.split("&");
	    for (var i = 0; i < vars.length; i++) {
	      var pair = vars[i].split("=");
	      if (pair[0] == variable) { return pair[1]; }
	    }
	  }
	  return (false);
	}
	onMounted(async()=>{
		const type = getQueryVariable('type');
		if(type==='todo'){
			listType.value = '待审核'
		}else if(type==='passed'){
			listType.value = '申请通过'
		}else if(type==='nopass'){
			listType.value = '申请退回'
		}
		const response = await axios.post('/registerDriver/regDriver/queryList', {
		  type:listType.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	})
	const fetchOrders = async () => {
		const response = await axios.post('/registerDriver/regDriver/queryList', {
		  type:listType.value,
		  key:'name',
		  value:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	};
	const grantCoupon=async(e)=>{
		const response = await axios.post('/registerDriver/regDriver/verifyInfo', {
		  id:e.id,
		  zt:'申请通过',
		  shyj:'信息核准无误'
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '审核通过!',
				type: 'success',
			})
			fetchOrders()
			jsjDialogVisible.value = false;
		}
	}
	const handleDtClick = (e)=>{
		billid.value = e.id;
		jsjDialogVisible.value = true;
	}
	const deleteData=async(e)=>{
		const response = await axios.post('/registerDriver/regDriver/verifyInfo', {
		  id:e.id,
		  zt:'申请退回',
		  shyj:shyj.value
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '申请退回!',
				type: 'success',
			})
			fetchOrders();
			jsjDialogVisible.value = false;
		}
	}
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
	const closeDtDialog=()=>{
		jsjDialogVisible.value = false;
	}
	const summitPass = async()=>{
		const response = await axios.post('/registerDriver/regDriver/verifyInfo', {
		  id:billid.value,
		  zt:'申请通过',
		  shyj:'信息核准无误'
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '审核通过!',
				type: 'success',
			})
			fetchOrders()
			jsjDialogVisible.value = false;
		}
	}
	const feedBack=async()=>{
		const response = await axios.post('/registerDriver/regDriver/verifyInfo', {
		  id:billid.value,
		  zt:'申请退回',
		  shyj:shyj.value
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '申请退回!',
				type: 'success',
			})
			fetchOrders()
			jsjDialogVisible.value = false;
		}
	}
</script>

<style scoped>
	.dialog-custom{
		height: 500px;
		overflow-y: scroll;
	}
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>